import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SiteContainer from '../components/SiteContainer.vue'

const routes = [
  {
    path: '/',
    name: 'homecontainer',
    component: SiteContainer,
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView
      },
      {
        path: '/dashboard/:id',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/PersoonlijkDashboard.vue')
      },
      {
        path: '/inschrijven',
        name: 'inschrijven',
        component: () => import(/* webpackChunkName: "inschrijven" */ '../views/InschrijvenView.vue'),
        children: [
          {
            path: 'persoonlijk',
            name: 'persoonlijk',
            component: () => import(/* webpackChunkName: "persoonlijk" */ '../components/Inschrijven/PersoonlijkeInformatie.vue'),
          },
          {
            path: 'parel',
            name: 'voertuig',
            component: () => import(/* webpackChunkName: "persoonlijk" */ '../components/Inschrijven/VoertuigInformatie.vue'),
          },
          {
            path: 'controle',
            name: 'controle',
            component: () => import(/* webpackChunkName: "persoonlijk" */ '../components/Inschrijven/ControleStap.vue'),
          },
        ]
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
