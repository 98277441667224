<template>
    <nav-bar></nav-bar>
    <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
            <div>
                <component :is="Component" />
            </div>
        </transition>
    </router-view>
</template>

<script>
    import NavBar from './NavBar.vue';

    export default {
        components: {
            NavBar
        }
    }

</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>