<template>
  <div id="main-bg" >
   <div class="container">
      <div id="hero-first" class="row">
        <div class="col-md-6 offset-md-3 text-center" id="logo-holder">
          <img src="@/assets/images/logo.svg" alt="" class="p-4 img-fluid animate__animated animate__zoomIn">
          <h4 class="mt-2">14 september 2024 &middot; IJsselstein</h4>
          <button
            v-if="!user_token"
            @click="$router.push({name: 'inschrijven'})"
            class="btn btn-attention btn-lg btn-block"
          >
            Schrijf je in 
            <font-awesome-icon :icon="['far', 'arrow-right']"/>
          </button>
          <button v-else
            @click="$router.push({name: 'dashboard', params: {id: user_token}})"
            class="btn btn-attention btn-lg btn-block"
          >
          Ga naar je dashboard
          <font-awesome-icon :icon="['far', 'arrow-right']"/>
          </button>
        </div>
      </div>
      <div id='hero-second' class="row pb-4">
        <div class="col-md-3">
          <img src="@/assets/images/homepage/tree1.svg" id="tree1" class="p-4 animate__animated animate__slideInRight">
        </div>
        <div class="font-kudrya col-md-6 d-block d-md-flex align-items-end justify-content-between animate__animated animate__fadeInUp">
          <div class="text-center text-md-start mb-3 mb-md-0">
            <h4>13:00 - 16:00</h4>
            <h3>14 september 2024</h3>
          </div>
          <div class="text-center text-md-end">
            <h4>Binnenstad</h4>
            <h3>IJsselstein, UT</h3>
          </div>
        </div>
        <div class="col-md-3">
          <img src="@/assets/images/homepage/tree1.svg" id="tree2" class="p-4 animate__animated animate__slideInLeft">
        </div>
      </div>
    </div>
  </div>
  <div class="container algemene-info">
    <div class="row mt-5">
      <div class="col-md-3">
        <h2 class="font-kudrya" id="sprookje-1">Ergens in een stad, <br> hier niet ver vandaan...</h2>
      </div>
      <div  
      class="col-md-6 bg-image-full">       
        <p>
          Vier met ons de magie van auto's en motoren tijdens het adembenemende Special Cars and Bikes evenement in IJsselstein! Op 14 september 2024 transformeert deze prachtige binnenstad zich in een paradijs voor autoliefhebbers, waar vintage schoonheden, glanzende supercars en klassiekers uit alle tijdperken samenkomen.
        </p>
      </div>
    </div>
    <div class="row mt-3 mb-5">
      <div class="col-md-3 offset-md-2">
        <h2 class="font-kudrya mt-2" id="sprookje-2">...verandert er een stadje <br> in een sprookjesbos...</h2>
      </div>
      <div  
      class="col-md-6 bg-image-full">       
        <p>
          Of je nu een fervent petrolhead bent of gewoon wilt genieten van de spectaculaire sfeer, dit evenement heeft voor elk wat wils. Bewonder de schitterende details en het vakmanschap van zorgvuldig gerestaureerde klassiekers, bewonder de nieuwste supercars uit deze tijd  en geniet van de sensatie van klassieke motoren die de binnenstad van IJsselstein kleuren.
        </p>
      </div>
    </div>
  </div>
  <div class="bg-b2 mb-5 overflow-none" id="wiggles">
      <div class="container">
        <div class="row">
          <div class="col-md-7 py-5 d-flex align-items-center">
            <div class="text-center text-md-start">
              <h2 class="red-flag mx-auto mx-md-0">
                Doe mee aan Special Cars & Bikes 
              </h2>
              <h4>Heb jij een speciaal voertuig of een voertuig met een speciaal verhaal? Doe dan mee! Van oldtimer tot supercar, van legervoertuig tot chopper. Motoren en auto's die nekken doen draaien zijn welkom!</h4>
              <button
            @click="$router.push({name: 'inschrijven'})"
            class="btn btn-attention btn-lg btn-block"
          >
            Schrijf je in 
            <font-awesome-icon :icon="['far', 'arrow-right']"/>
          </button>
            </div>
          </div>
          <div class="col-md-5 py-4">
            <img src="@/assets/images/homepage/ferrari.svg" id="ferrari" class="animate__animated animate__slideInRight animate__delay-3s d-none d-md-block">
          </div>
        </div>
      </div>
    </div>
  <div class="bg-b1">
    <div class="container">
      <div class="row">
        <div class="col-md-3 d-flex align-items-center justify-content-end">
          <img src="@/assets/images/homepage/kever.svg" id="kever" class="animate__animated animate__zoomInLeft animate__delay-s">
        </div>
        <div class="col-md-7 offset-md-1" id="pamflet">
          <h2 class="h2 font-kudrya">Programma voor deelnemers</h2>
          <ul class="planning">
            <li>
              <span class="time">12:00 - 13:00</span>
              Aankomst en registratie van deelnemers Special Cars & Bikes. Locatie zal via e-mail worden gecommuniceerd.
            </li>
            <li>
              <span class="time">13:00 - 15:00</span>
              Show en Shine: Parkeer je auto op onze speciale showlocatie en laat je trots bewonderen door andere deelnemers en bezoekers. Neem de tijd om rond te lopen en andere prachtige voertuigen te bekijken.
            </li>
            <li>
              <span class="time">15:00 - 15:30</span>
              Prijsuitreiking voor de leukste/meest unieke deelnemer van het evenement. Uitreiking bij het oude stadhuis. 
            </li>
            <li>
              <span class="time">15:30 - 16:00</span>
              Meet, Greet and Goodbye: Een laatste moment om een ronde te maken, foto’s te maken en te praten met de liefhebbers.  
            </li>
            <li>
              <span class="time">16:00 - 16:30</span>
              Time to say goodbye: Een afscheid met een lach en een traan, want helaas is het weer tijd voor iedereen om te gaan. Een voor een verlaten de specials de binnenstad onder luid applaus
            </li>
          </ul>
        </div>
      </div>
      <div class="row my-5">
        <div class="col-md-3 text-white">
          <h3>Contact</h3>
          <p>
            Bij vragen of opmerking  kun je contact opnemen via
            <a target='_blank' href="mailto:organisatie@specialcarsandbikes.nl">organisatie@specialcarsandbikes.nl</a>. 
          </p>
        </div>
        <div class="col-md-6 offset-md-3 text-white">
                <h3>Huisreglement</h3>
                <p>
                    <ul>
                        <li>Bij het betreden van het evenemententerrein verklaart men kennis te hebben genomen van en akkoord te zijn met de voorwaarden en huisregels zoals deze gelden tijdens het evenement.</li>
                        <li>Een ieder is verplicht aanwijzingen van de vrijwilligers op te volgen.</li>
                        <li> Indien een bezoeker zich niet houdt aan de algemene voorwaarden en huisreglement zal hij worden verwijderd.</li>
                        <li>De organisatie, de eigenaren van de locatie dan wel personen die op deze locatie werkzaam zijn, kunnen niet aansprakelijk gesteld worden voor enig letsel en/of materiële dan wel immateriële schade die bezoekers van de locatie mochten ondervinden.</li>
                        <li>Bij calamiteiten waarschuwen wij de politie.</li>
                    </ul>
                </p>
            </div>
        
      </div>
    </div>
  </div>
</template> 




<script>
  import 'animate.css';
  import Typewriter from 'typewriter-effect/dist/core';
  export default {
    name: 'HomeView',
    data () {
      return {
        user_token: null,
      }
    },
    mounted() {
      let token = localStorage.getItem('user_token');
      if(token){
        this.user_token = token;
      }
    //  add typewriter effect to sprookje-1
      const sprookje1 = document.getElementById('sprookje-1');
      const typewriter = new Typewriter(sprookje1, {
        loop: false,
        delay: 30,
        cursor: '',
      });
      typewriter
        .pauseFor(1000)
        .typeString('Ergens in een stad, <br> hier niet ver vandaan...')
        .start();

      //  add typewriter effect to sprookje-2
      const sprookje2 = document.getElementById('sprookje-2');
      const typewriter2 = new Typewriter(sprookje2, {
        loop: false,
        delay: 30,
        cursor: '',
      });
      typewriter2
        .pauseFor(4000)
        .typeString('...verandert er een stadje <br> in een sprookjesbos...')
        .start();
    }
  }
</script>

<style scoped>
  #main-bg{
    background: rgb(10,1,68);
    background: -moz-linear-gradient(180deg, rgba(10,1,68,1) 0%, rgba(9,143,195,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(10,1,68,1) 0%, rgba(9,143,195,1) 100%);
    background: linear-gradient(180deg, rgba(10,1,68,1) 0%, rgba(9,143,195,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a0144",endColorstr="#098fc3",GradientType=1);
    color: var(--prim-l1);
    overflow: hidden;
    position: relative;
  }
  #hero-first{
    min-height: 70vh;
  }
  #hero-second{
    min-height: 30vh;
  }
  #hero-second h3{
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  #logo-holder{
    margin-top: 20vh;
  }

  #logo-holder img{
    margin-bottom: -40px;
  }

  #tree1{
    margin-top: -500px;
    width: max(400px, 30vw);
    height: auto;
    position: absolute;
    margin-left: -100px;
  }

  #tree2{
    margin-top: -500px;
    width: max(400px, 30vw);
    height: auto;
    position: absolute;
    margin-right: -100px;

    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);

  }

  @media (max-width: 1280px){
    #tree1{
      margin-left: -200px;
    }
    #tree2{
      margin-right: -200px;
    }
    #logo-holder img{
      margin-bottom: -20px;
    }
  }

  @media (max-width: 768px) {
    #hero-first{
      min-height: 60vh;
    }
    #hero-second{
      min-height: 50vh;
    }
    #logo-holder{
      margin-top: 10vh;
    }
    #tree1{
      margin-top: 0px;
      width: max(240px, 30vw);
    }
    #tree2{
      margin-top: 0px;
      width: max(240px, 30vw);
    }
  }

.algemene-info{
  color: var(--prim-l1);
  /* background: var(--prim-b2); */
  padding: 2rem;
}

.algemene-info p{
  font-size: 1.4rem;
  font-weight: 500;
}

#pamflet{
  border: 20px solid var(--prim-l2);
  background: var(--prim-l1);
  color: var(--prim-b1);
  padding: 20px;
}
#wiggles{
  --mask:
        radial-gradient(48.02px at 50% 67.50px,#000 99%,#0000 101%) calc(50% - 60px) 0/120px 100%,
        radial-gradient(48.02px at 50% -37.5px,#0000 99%,#000 101%) 50% 30px/120px 100% repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  padding: 70px 0 40px;
}

.planning li{
  margin-bottom: 1rem;
}


@media screen and (max-width: 768px) {
  #pamflet{
    margin-top: -200px;
    z-index: 10;
  }
  #wiggles{
    padding: 70px 0 0px;
  }
  .planning {
    padding-left: 0;
  }

}

#kever{ 
  height: 400px;
  width: auto;
  float: right;
}

#ferrari{ 
  height: 300px;
  width: auto;
  float: left;
}

.overflow-none{
  overflow: hidden;
}

.planning{
  font-size: 1.1rem;
  font-weight: 500;
  list-style: none;
}


.planning .time{
  background: var(--prim-b2);
  color: var(--prim-l1);
  padding: 0.2rem 0.5rem;
  margin-right: 8px;
}



</style>