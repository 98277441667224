import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import Axios from 'axios'

Axios.defaults.baseURL = 'https://scab-serv.cld9.nl/api/';
Axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fat, fal, fad, far)

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import '@/assets/styles/theme.css'

createApp(App)
.component('font-awesome-icon', FontAwesomeIcon)
.use(store)
.use(router)
.mount('#app')
