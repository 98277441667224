<template>
    <header class='globalNav'>
        <nav class="navbar navbar-expand-lg">
        <div class="container-xl">
            <router-link class="navbar-brand" aria-label="Ga naar de homepagina" :to="{ name: 'home'}"  id='centernav'>
                <img src="@/assets/images/icon.svg" alt="Logo" height='40' class="logo me-2">
            </router-link>
            <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerMain" aria-controls="navbarTogglerMain" aria-expanded="false" aria-label="Toggle navigatie">
                <span class="navbar-toggler-icon"></span>
            </button> -->
            <div class="collapse navbar-collapse" id="navbarTogglerMain">
                <ul class="navbar-nav mb-lg-0 ">

                </ul>
                <ul class="navbar-nav mb-lg-0 ms-auto">
                    <!-- <router-link  :to="{ name: 'home' }" custom v-slot="{ navigate, href, isActive }">
                        <a :href="href" :class="[isActive && 'active']" class="nav-link " @click="navigate">Contact</a>
                    </router-link>
                    <router-link  :to="{ name: 'home' }" custom v-slot="{ navigate, href, isActive }">
                        <a :href="href" :class="[isActive && 'active']" class="nav-link" @click="navigate">Inloggen</a>
                    </router-link> -->
                </ul>
            </div>
        </div>
        </nav>
    </header>
</template>

<style scoped>
.globalNav{
    margin-bottom: -20px;
    z-index: 100;
    position: relative;
}
#centernav{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    margin-top: 20px;
}

.nav-link{
    color: var(--prim-l1);
}
</style>